import React from "react"
import { shape } from "prop-types"
import styled from "styled-components"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import News from "../components/News"
import Download from "../components/Download"
import { Heading1 } from "../components/_globalSettings/typography"
// import { color } from "../components/_globalSettings/skin"
import { breakpoint } from "../components/_globalSettings/var"

const Articles = styled.div`
	width: 63.6%;
	padding-bottom: 103px;
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
	}
`

/* const LoadMore = styled.span`
	color: ${color.main.primary1};
	${typography.ButtonS};
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
` */

const MediaWrap = styled.div`
	display: flex;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
	}
`

const Heading = styled.div`
	h1 {
		padding-top: 94px;
		padding-bottom: 50px;
	}
`

const Media = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<Heading>
					<Heading1>Pro média</Heading1>
				</Heading>
			</Row>
			<Row>
				<MediaWrap>
					<Articles>
						{pageContext.news.nodes.map(preview => (
							<News {...preview} />
						))}
						{/* <LoadMore>Načíst další aktuality (+3)</LoadMore> */}
					</Articles>
					<Download
						photo={pageContext.photo}
						logoSVG={pageContext.logoSVG}
						logoPNG={pageContext.logoPNG}
						{...pageContext.download}
					/>
				</MediaWrap>
			</Row>
		</Layout>
	)
}

Media.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Media
