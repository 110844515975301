import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { typography } from "../_globalSettings/typography"
import { color } from "../_globalSettings/skin"

const StyledNews = styled.div`
	width: 100%;
	max-width: 1125px;
	display: flex;
	align-items: top;
	padding-bottom: 70px;
	.news__type {
		${typography.AllCapsBoldXS};
		font-weight: 500;
		color: ${color.main.text2};
	}
	.news__top-line {
		display: flex;
		justify-content: space-between;
	}
	.news__left {
		width: 61.3%;
	}
	.news__right {
		width: 30.8%;
		margin-left: 7.9%;
		text-align: right;
	}
	.news__date {
		color: ${color.main.text2};
		${typography.BodyS};
	}
	.news__headline {
		margin: 16px 0;
		color: ${color.main.text1};
	}
	.news__perex {
		${typography.BodyL};
		color: ${color.main.text2};
	}
	.news__link {
		display: inline-block;
		position: relative;
		padding-right: 64px;
		${typography.ButtonS};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&:after {
			content: "";
			display: inline-block;
			height: 18px;
			width: 18px;
			background-size: 100%;
			background-image: url("/css/link-out.svg");
			position: absolute;
			bottom: 50%;
			right: 33px;
			transform: translateY(50%);
		}
	}
	.news__links {
		margin-top: 20px;
		.download {
			${typography.ButtonS};
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		padding-bottom: 45px;
		flex-direction: column;
		.news__left {
			width: 100%;
		}
		.news__right {
			width: 100%;
			text-align: center;
			margin-left: 0;
			margin-top: 64px;
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		.news__link {
			margin-bottom: 10px;
		}
	}
`

export default StyledNews
