import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"

const StyledDownload = styled.div`
	width: 31.4%;
	margin-left: 5%;
	display: flex;
	padding: 64px;
	flex-direction: column;
	align-self: flex-start;
	background: ${color.main.primary1};
	position: relative;
	h3 {
		font-size: 33px;
		line-height: 130%;
	}
	.download__pre-column {
		display: flex;
		flex-direction: column;
	}
	.download__post-column {
		display: flex;
		flex-direction: column;
	}
	.pre-column__headline {
		padding-bottom: 3px;
		color: #fff;
	}
	.pre-column__text {
		color: #fff;
		${typography.BodyL};
	}
	.column__headline {
		padding-bottom: 16px;
		color: #fff;
	}
	.column__text {
		${typography.SubtitleM};
		margin-bottom: 8px;
		display: inline-block;
		color: #fff;
		font-weight: 500;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-left: 12px;
			position: relative;
			top: 3px;
			background-image: url("/css/download-white.svg");
		}
	}
	.column__image {
		margin-bottom: 24px;
		margin-top: 64px;
	}

	@media (max-width: ${breakpoint.preMax.to}px) {
		padding: 32px;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: calc(100% + 64px);
		left: -32px;
		margin: 0;
		padding: 100px 32px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		width: calc(100% + 32px);
		left: -16px;
		padding: 100px 16px;
	}
`

export default StyledDownload
