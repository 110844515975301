import React from "react"
import { string, shape } from "prop-types"
import StyledNews from "./style"
import Image from "../Image"
import A from "../Link"
import { Heading6 } from "../_globalSettings/typography"

const News = ({ type, date, headline, perex, origin, image, download }) => {
	const formattedDate = new Date(date)
	return (
		<StyledNews>
			<div className="news__left">
				<div className="news__top-line">
					<p className="news__type">{type}</p>
					<p className="news__date">
						{`${formattedDate.getDate()}. `}
						{`${formattedDate.getMonth() + 1}. `}
						{`${formattedDate.getFullYear()}`}
					</p>
				</div>
				<Heading6 className="news__headline">{headline}</Heading6>
				<p className="news__perex">{perex}</p>
				<p className="news__links">
					{(origin || origin !== "") && (
						<A href={origin} className="news__link">
							Celý článek
						</A>
					)}
					{(download || download !== "") && <A href={download}>Stáhnout</A>}
				</p>
			</div>
			<div className="news__right">
				<Image className="news__image" data={image} alt={headline} />
			</div>
		</StyledNews>
	)
}

export const NewsProps = {
	type: string.isRequired,
	date: string.isRequired,
	headline: string.isRequired,
	perex: string.isRequired,
	origin: string.isRequired,
	image: shape({
		publicURL: string.isRequired,
	}),
	download: string,
}

News.defaultProps = {
	download: "",
}

News.propTypes = {
	...NewsProps,
}

export default News
