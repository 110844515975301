import React from "react"
import { string, shape, arrayOf } from "prop-types"
import StyledDownload from "./style"
import A from "../Link"
import Image from "../Image"
import { Heading3, Heading5 } from "../_globalSettings/typography"

const Download = ({ headline, content, columns, photo, logoSVG, logoPNG }) => {
	return (
		<>
			<StyledDownload>
				<div className="download__pre-column">
					<div>
						<Heading3 className="pre-column__headline">{headline}</Heading3>
						<p className="pre-column__text">{content}</p>
					</div>
				</div>
				<div className="download__post-column">
					<div className="download__column" key={columns[0].headline}>
						<Image
							className="column__image"
							data={columns[0].image}
							alt={columns[0].headline}
						/>
						<Heading5 className="column__headline">
							{columns[0].headline}
						</Heading5>
						<p>
							<A className="column__text" href={logoSVG.publicURL}>
								Logo (.svg)
							</A>
						</p>
						<p>
							<A className="column__text" href={logoPNG.publicURL}>
								Logo (.png)
							</A>
						</p>
					</div>
					<div className="download__column" key={columns[1].headline}>
						<Image
							className="column__image"
							data={columns[1].image}
							alt={columns[1].headline}
						/>
						<h3 className="column__headline">{columns[1].headline}</h3>
						{columns[1].links.map(link => (
							<p key={link.label}>
								<A className="column__text" href={photo.publicURL}>
									Fotografie do médií (.zip)
								</A>
							</p>
						))}
					</div>
				</div>
			</StyledDownload>
		</>
	)
}

export const DownloadProps = {
	headline: string.isRequired,
	content: string.isRequired,
	columns: arrayOf(
		shape({
			image: shape({
				publicURL: string.isRequired,
			}).isRequired,
			headline: string.isRequired,
			links: arrayOf(
				shape({
					url: string.isRequired,
					label: string.isRequired,
				}).isRequired
			).isRequired,
		}).isRequired
	).isRequired,
	photo: shape({
		publicURL: string,
		name: string,
	}),
	logoSVG: shape({
		publicURL: string,
		name: string,
	}),
	logoPNG: shape({
		publicURL: string,
		name: string,
	}),
}

Download.propTypes = {
	...DownloadProps,
}

export default Download
